import { useState } from 'react';
import { Button, Form } from 'semantic-ui-react';
import { getRandomString } from 'ts-closure-library/lib/string/string';
import type { Callback } from 'ts/base/Callback';
import { useTeamscaleServiceClient } from 'ts/base/hooks/TeamscaleServiceClientHook';
import { BranchSelector } from 'ts/commons/BranchSelector';
import { DateUtils } from 'ts/commons/DateUtils';
import { NavigationHash } from 'ts/commons/NavigationHash';
import { StringUtils } from 'ts/commons/StringUtils';
import { TimePickerDialog } from 'ts/commons/time/components/TimePickerDialog';
import { TimeContext } from 'ts/commons/time/TimeContext';
import { TimeUtils } from 'ts/commons/time/TimeUtils';
import type { TypedTimestamp } from 'ts/commons/time/TypedPointInTime';
import { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';

type CommitSelectorProps = {
	initialCommit?: UnresolvedCommitDescriptor;
	currentProject?: string;
	onChange: Callback<UnresolvedCommitDescriptor>;
};

/** A component for selecting branches and a time value via the time selection dialog. */
export function CommitSelector({
	initialCommit = UnresolvedCommitDescriptor.createLatestOnDefaultBranch(),
	currentProject = NavigationHash.getProject(),
	onChange
}: CommitSelectorProps): JSX.Element | null {
	const client = useTeamscaleServiceClient();

	const [timeValue, setTimeValue] = useState(getTypedTimestampFrom(initialCommit));
	const [selectedBranch, setBranch] = useState(initialCommit.getBranchName());

	let formattedTimestamp = null;
	if (timeValue?.value.timestamp != null) {
		formattedTimestamp = DateUtils.formatTimestamp(timeValue.value.timestamp);
	}

	let timestampText = 'Repository at most recent revision';
	if (formattedTimestamp != null) {
		timestampText = 'Repository at ' + formattedTimestamp;
	}

	let projectIds: string[] = [];
	if (!StringUtils.isEmptyOrWhitespace(currentProject)) {
		projectIds = [currentProject];
	}

	const timePickerId = getRandomString();
	return (
		<Form className="commit-selector-container">
			<Form.Group inline>
				<Form.Field label="Branch" />
				<Form.Field>
					<BranchSelector
						selectorId="path-dialog-branch-selector"
						projectIds={projectIds}
						selectedBranch={selectedBranch}
						onBranchChanged={branch => {
							setBranch(branch);
							onChange(new UnresolvedCommitDescriptor(timeValue?.value.timestamp, branch));
						}}
					/>
				</Form.Field>
				<Form.Field label="Folder Structure:" />
				<Form.Field id="path-dialog-jump-to-time-button">
					<TimePickerDialog
						dialogTitle="Timetravel to..."
						onChange={newTime => {
							// Resolve the selected point in time relative to a possible time travel
							new TimeContext(client, NavigationHash.getCurrentCommit())
								.resolveCommit(newTime)
								.then(commit => {
									setTimeValue(getTypedTimestampFrom(commit));
									onChange(new UnresolvedCommitDescriptor(commit.getTimestamp(), selectedBranch));
								});
						}}
						onHide={() => {
							Object.keys(localStorage)
								.filter(x => x.startsWith('timepicker-' + timePickerId))
								.forEach(x => localStorage.removeItem(x));
						}}
						disabledTabs={[]}
						hideTimeBox={false}
						defaultValue={timeValue}
						id={timePickerId}
						projects={projectIds}
						trigger={<Button secondary icon="wait" content={timestampText} />}
					/>
				</Form.Field>
			</Form.Group>
		</Form>
	);
}

/** Fetches the timestamp from the given commit or null */
function getTypedTimestampFrom(commit: UnresolvedCommitDescriptor): TypedTimestamp | null {
	const timestamp = commit.getTimestamp();
	if (timestamp != null) {
		return TimeUtils.timestamp(timestamp);
	}
	return timestamp;
}
