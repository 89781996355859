import { useQuery } from '@tanstack/react-query';
import type { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import { useTeamscaleServiceClient } from 'ts/base/hooks/TeamscaleServiceClientHook';
import type { FileListQuery, PathChecker } from 'ts/commons/dialog/PathEntitySelectionModal';
import {
	FILE_LIST_QUERY_EMPTY,
	FILE_LIST_QUERY_ERROR,
	FileListQueryData,
	PathEntitySelectionModal,
	useProjectIds
} from 'ts/commons/dialog/PathEntitySelectionModal';
import {
	buildAndSortEntries,
	getPathEntryForProjects,
	insertPathPrefix
} from 'ts/commons/dialog/PathEntitySelectionUtils';
import { NavigationHash } from 'ts/commons/NavigationHash';
import { StringUtils } from 'ts/commons/StringUtils';
import { UniformPath } from 'ts/commons/UniformPath';
import { EResourceType } from 'typedefs/EResourceType';
import { EType } from 'typedefs/EType';

type ProjectAndPathSelectionModalProps = {
	onSave: (project: string, path: UniformPath, commit: UnresolvedCommitDescriptor) => void;
	initialProject?: string;
	initialPath?: UniformPath;
	initialCommit?: UnresolvedCommitDescriptor;
	pathChecker?: PathChecker[];
	triggerElement?: JSX.Element;
};

export function ProjectAndPathSelectionModal({
	onSave,
	triggerElement,
	initialProject = NavigationHash.getProject(),
	initialPath,
	initialCommit
}: ProjectAndPathSelectionModalProps): JSX.Element {
	return (
		<PathEntitySelectionModal
			initialProject={initialProject}
			initialPath={initialPath}
			initialCommit={initialCommit}
			triggerElement={triggerElement}
			onSave={(project: string | undefined, path: UniformPath, commit: UnresolvedCommitDescriptor) => {
				onSave(project!, path, commit);
			}}
			title="Select project and path"
			projectsSelectable
			useEntries={useProjectAndPathData}
		/>
	);
}

function useProjectAndPathData(
	project: string,
	path: UniformPath,
	selectedCommit: UnresolvedCommitDescriptor,
	enabled = true
): FileListQuery {
	const projects = useProjectIds();
	const showProjects = StringUtils.isEmptyOrWhitespace(project) && path.isEmpty();

	// Empty project should not be invalid, for example when selecting a project
	const invalidProject = !StringUtils.isEmptyOrWhitespace(project) && !projects?.includes(project);

	// File list (in contrast to project list) should only be fetched according to the following conditions
	const doFetchFileList = enabled && !showProjects && !invalidProject;
	const pathResourceType = useResourceType(project, path, selectedCommit, doFetchFileList);
	const containers = useContainers(project, path, selectedCommit, doFetchFileList);

	if (!enabled) {
		return FILE_LIST_QUERY_EMPTY;
	}

	const invalidPath = pathResourceType === EResourceType.UNKNOWN.toString();
	// Projects need to be checked here again in case the current project is empty
	if (!projects || invalidProject || invalidPath || (!showProjects && !pathResourceType)) {
		return FILE_LIST_QUERY_ERROR;
	}

	if (showProjects) {
		return FileListQueryData(getPathEntryForProjects(projects).filter(entry => entry.name.startsWith(project)));
	}
	return containers;
}

/** Fetches the info for the file list entries. Returns null if the path is invalid */
export function useContainers(
	project: string,
	path: UniformPath,
	commit: UnresolvedCommitDescriptor,
	enabled = true
): FileListQuery {
	const pathResourceType = useResourceType(project, path, commit, enabled);
	const childResourceTypes = useChildResourceTypes(project, path, commit, enabled);
	const displayMetrics = useDisplayMetrics(project, path, commit, enabled);

	if (!enabled) {
		return FILE_LIST_QUERY_EMPTY;
	}

	if (!pathResourceType || pathResourceType === EResourceType.UNKNOWN.toString() || !childResourceTypes) {
		return FILE_LIST_QUERY_ERROR;
	}

	const entries = buildAndSortEntries(project, childResourceTypes);
	displayMetrics.forEach(path => insertPathPrefix(project, path, entries));

	return FileListQueryData(entries);
}

function useChildResourceTypes(
	project: string,
	path: UniformPath,
	commit: UnresolvedCommitDescriptor,
	enabled = true
): Map<string, EResourceType> | null {
	const resourceTypesWithChildrenQuery = useResourceTypesOfChildren(project, path, commit, enabled);

	if (!resourceTypesWithChildrenQuery) {
		return null;
	}

	const mapEntries = (obj: Record<string, string>): Array<[string, EResourceType]> => {
		// @ts-ignore
		return Object.keys(obj).map(k => [k, EResourceType[obj[k]]]);
	};
	return new Map(mapEntries(resourceTypesWithChildrenQuery));
}

/**
 * Fetches the resource type for the given uniform path.
 *
 * The given path can be invalid, then the resource type just will be UNKNOWN, however the project must exist, otherwise
 * this causes a 404 error.
 */
function useResourceType(
	project: string,
	path: UniformPath,
	commit: UnresolvedCommitDescriptor,
	enabled = true
): string | null | undefined {
	const client = useTeamscaleServiceClient();

	return useQuery(
		['resource-type', project, path.getPath(), commit, false],
		() => client.getResourceType(project, path.getPath(), commit, false),
		{
			enabled: enabled && !StringUtils.isEmptyOrWhitespace(project),
			keepPreviousData: true
		}
	).data;
}

/**
 * Fetches the resource types of the children for the given uniform path.
 *
 * The given path can be invalid, then the resource type for the children will just be empty, however the project must
 * exist, otherwise this causes a 404 error.
 *
 * This is an extra method, even though the difference to {@see useResourceType} is only a single boolean, because the
 * return type of the used teamscale client method returns a completely different type.
 */
function useResourceTypesOfChildren(
	project: string,
	path: UniformPath,
	commit: UnresolvedCommitDescriptor,
	enabled: boolean
): Record<string, string> | undefined {
	const client = useTeamscaleServiceClient();

	return useQuery(
		['resource-type', project, path.getPath(), commit, true],
		() => client.getResourceType(project, path.getPath(), commit, true),
		{
			enabled: enabled && !StringUtils.isEmptyOrWhitespace(project),
			keepPreviousData: true
		}
	).data;
}

function useDisplayMetrics(
	project: string,
	path: UniformPath,
	commit: UnresolvedCommitDescriptor,
	enabled: boolean
): string[] {
	return [
		EType.NON_CODE.prefix,
		EType.ISSUE_QUERY.prefix,
		EType.TEST_IMPLEMENTATION.prefix,
		EType.TEST_EXECUTION.prefix,
		EType.TEST_QUERY.prefix,
		EType.SPEC_ITEM_QUERY.prefix
	].flatMap(constant => {
		let usePath = new UniformPath('');
		if (enabled && !StringUtils.isEmptyOrWhitespace(project)) {
			usePath = new UniformPath(constant);
		}

		// eslint-disable-next-line react-hooks/rules-of-hooks
		return useResourceType(project, usePath, commit, enabled) === EResourceType.CONTAINER.name &&
			path.isProjectRoot()
			? [constant]
			: [];
	});
}
